import React from 'react';

const ProjectAbout = ({ locale, customer, industry, services, technologies }) => {

    return (
        <div className="project__about">
            <div className="project__about--container">
                <h4 className="project__about--heading">
                    {locale === 'en' ? 'Customer' : 'Kunde'}
                </h4>
                <p dangerouslySetInnerHTML={{ __html: customer }} />
            </div>
            <div className="project__about--container">
                <h4 className="project__about--heading">
                    {locale === 'en' ? 'Industry' : 'Industrie'}
                </h4>
                <p dangerouslySetInnerHTML={{ __html: industry }} />
            </div>
            <div className="project__about--container">
                <h4 className="project__about--heading">
                    {locale === 'en' ? 'Services' : 'Dienstleistungen'}
                </h4>
                <p dangerouslySetInnerHTML={{ __html: services }} />
            </div>
            <div className="project__about--container">
                <h4 className="project__about--heading">
                    {locale === 'en' ? 'Technologies' : 'Technologien'}
                </h4>
                <p dangerouslySetInnerHTML={{ __html: technologies }} />
            </div>
        </div>
    )
}

export default ProjectAbout;