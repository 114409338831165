import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Link from '../components/Link';
import ProjectAbout from '../components/ProjectAbout';
import NextProject from '../components/NextLink/NextProject';
import useSiteMetadata from '../helpers/useSiteMetadata';
import locales from '../locales.json';

const Project = props => {
  const { siteUrl } = useSiteMetadata();
    const lang = props.pageContext.locale;
    const data = lang === 'en' ? props.data.en : props.data.de;
    const { title, slug, projectSeo, description, projectBackground, customer, industry, services, technologies, mainImage, projectContent, buttonCta, callToAction } = data;
    const breadcrumSeoItems = JSON.parse(JSON.stringify(`
        {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "name": "${ lang === 'en' ? locales.menu.ourWork.en : locales.menu.ourWork.de }",
            "item": "${ siteUrl }/${ lang === 'de' ? 'de/' : '' }our-work/"
          },{
            "@type": "ListItem",
            "position": 2,
            "name": "${ title }",
            "item": "${ siteUrl }/${ lang === 'de' ? 'de/' : '' }our-work/${slug}/"
          }]
        }
    `))

    return (
      <Layout lang={lang} location={props.location} link='project' buttonCta={buttonCta} callToAction={callToAction}>
          <Seo title={projectSeo && projectSeo.title} description={projectSeo && projectSeo.description} image={projectSeo && projectSeo.image && projectSeo.image.url} slug={`our-work/${slug}`} />
          <Helmet>
              <script type="application/ld+json">
                  { breadcrumSeoItems }
              </script>
          </Helmet>
          <div className="project">
              <div className="project__main">
                <nav className='breadcrumb'>
                  <Link lang={ lang } to='/our-work/'>
                      { lang === 'en' ? locales.menu.ourWork.en : locales.menu.ourWork.de}
                  </Link>
                  <span className='divider'>|</span>
                  <span>{ title }</span>
                </nav>
                  <div className="container">
                      <div className="project__main--container">
                          <h1 className="project__name" dangerouslySetInnerHTML={{ __html: title }} />
                          <p className="project__desc text" dangerouslySetInnerHTML={{ __html: description }} />
                      </div>
                      <ProjectAbout locale={lang} customer={customer} industry={industry} services={services} technologies={technologies} />
                      <div className="project__main--thumb" style={{ backgroundColor: projectBackground.rgb }}>
                          <GatsbyImage
                            image={mainImage.gatsbyImageData}
                            alt={ mainImage.alt }
                            title={ mainImage.alt }
                            style={{ width: '100%', maxWidth: '600px', height: '100%', maxHeight: '400px'}} />
                      </div>
                  </div>
              </div>
              <div className="project__content">
                  <div className="container">
                      {projectContent.map((content, index) => {
                          const { fullWidth, image, text } = content;

                          const fullLayout = (
                              <div className="project__box--container" key={index}>
                                  <div className="project__box project__box--full">
                                      <GatsbyImage image={image.gatsbyImageData} alt={ image.alt } title={ image.alt } />
                                  </div>
                              </div>
                          )

                          const halfLayout = (
                              <div className="project__box--container" key={index}>
                                  <div className="project__box project__box--half left">
                                    <GatsbyImage className="image-sticky" image={image.gatsbyImageData} alt={ image.alt } title={ image.alt } />
                                  </div>
                                  <div className="project__box project__box--half" dangerouslySetInnerHTML={{ __html: text }} />
                              </div>
                          )

                          return fullWidth ? fullLayout : halfLayout;
                      })}
                  </div>
              </div>
              <div className="container">
                  <div className="post__content--next">
                      <div className="next__content">
                          <div className="next">
                              {lang === 'en' ? 'Next Project' : 'Nächstes Projekt'}
                          </div>
                          <NextProject locale={lang} current={slug} />
                      </div>
                  </div>
              </div>
          </div>
      </Layout>
    );
}

export const query = graphql`
query SingleProject($slug: String) {
    en:datoCmsProject(slug: {eq: $slug}, locale: {eq: "en"}) {
      title
      slug
      projectSeo {
        title
        description
        twitterCard
        image {
          url
        }
      }
      description
      projectBackground {
        rgb
      }
      customer
      industry
      services
      technologies
      mainImage {
        gatsbyImageData(width: 1120)
        alt
      }
      projectContent {
        fullWidth
        image {
          gatsbyImageData(width: 1120)
          alt
        }
        text
      }
    }
    de:datoCmsProject(slug: {eq: $slug}, locale: {eq: "de"}) {
      title
      slug
      projectSeo {
        title
        description
        twitterCard
        image {
          url
        }
      }
      description
      projectBackground {
        rgb
      }
      customer
      industry
      services
      technologies
      mainImage {
        gatsbyImageData(width: 1120)
        alt
      }
      projectContent {
        fullWidth
        image {
          gatsbyImageData(width: 1120)
          alt
        }
        text
      }
    }
  }
`;

export default Project;