import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Link from '../Link';

const query = graphql`
query NextLinkQuery {
    enProjects: allDatoCmsProject(filter: {locale: {eq: "en"}, showProject: {ne: false}}) {
        nodes {
          slug
          title
        }
    }
    deProjects: allDatoCmsProject(filter: {locale: {eq: "de"}, showProject: {ne: false}}) {
        nodes {
          slug
          title
        }
    }
}
`;

const NextProject = ({ locale, current }) => {

    const data = useStaticQuery(query);

    const projects = locale === 'en' ? data.enProjects.nodes : data.deProjects.nodes;

    const findProjects = projects.findIndex(project => project.slug === current);

    const next = projects[findProjects + 1] ? projects[findProjects + 1] : projects[0];

    return (
        <Link lang={locale} to={`/our-work/${next.slug}/`} className="btn btn__secondary">
            {`> ${next.title}`}
        </Link>
    )
}

export default NextProject;